<template>
  <section class="dashboard-wishlist">
    <div class="title-main">
      <h3>View analytics for your store.</h3>
      <litepie-datepicker
        :formatter="formatter"
        overlay
        ref="myRef"
        v-model="dateValue"
      />
    </div>
    <div class="row-boxs">
      <div class="box">
        <div class="main-box">
          <img src="../assets/images/apps/actions.svg" alt="" />
          <div class="details">
            <h2 class="number">
              {{ statistics.total_actions_in_wish_lists }}
            </h2>
            <p>Total actions performed on your store</p>
          </div>
        </div>
        <div class="bottom-title">Wishlisted Actions</div>
      </div>

      <div class="box customers">
        <div class="main-box">
          <img src="../assets/images/apps/customers.svg" alt="" />
          <div class="details">
            <h2 class="number">
              {{ statistics.customers_added_products_count }}
            </h2>
            <p>Total customers added products to wishlist</p>
          </div>
        </div>
        <div class="bottom-title">Customers</div>
      </div>

      <div class="box products">
        <div class="main-box">
          <img src="../assets/images/apps/product.svg" alt="" />
          <div class="details">
            <h2 class="number">
              <span>
                {{ statistics.wish_lists_products_count }}
              </span>
              /

              <span class="allProducts">
                {{ statistics.Wish_list_all_products_id_count }}
              </span>
            </h2>
            <p>Total products added to the wishlist by customers</p>
          </div>
        </div>
        <div class="bottom-title">Wishlisted Products</div>
      </div>
    </div>

    <!--start products-->
    <section class="table-container-statistics">
      <div class="prducts">
        <h4>Top Products Added to Wishlist</h4>
        <DataTable
          :value="products"
          :paginator="true"
          :rows="7"
          :hidden="statusTopProducts == false"
          :loading="loadingPro"
          :lazy="true"
        >
          <Column field="product_name" header="Product Name"></Column>
          <Column field="product_id" header="Product Id"></Column>
          <Column field="counter" header="Counter"></Column>
          <Column field="updated_at" header="Updated at"></Column>
        </DataTable>

        <div class="empty" :hidden="statusTopProducts == true">
          <p>{{ emptyProdutsMes }}</p>
        </div>
      </div>
      <div class="">
        <h4>Top Customers by Wishlist Added</h4>
        <DataTable
          :value="customers"
          :paginator="true"
          :rows="7"
          :hidden="statusTopCustomers == false"
          :lazy="true"
          :loading="loadingCus"
          @row-click="onRowClick"
        >
          <Column field="customer_name" header="Customer Name">
            <template #body="slotProps">
              {{
                slotProps.data.customer_name
                  ? slotProps.data.customer_name
                  : "anonymous"
              }}
            </template>
          </Column>
          <Column field="customer_email" header="Customer Email">
            <template #body="slotProps">
              {{
                slotProps.data.customer_email
                  ? slotProps.data.customer_email
                  : "anonymous"
              }}
            </template>
          </Column>
          <Column field="distinct_products" header="Counter"></Column>
          <Column field="updated_at" header="Last Updated at"></Column>
        </DataTable>

        <div class="empty" :hidden="statusTopCustomers == true">
          <p>{{ emptyCustomersMes }}</p>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { ref, watch } from "vue";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import LitepieDatepicker from "litepie-datepicker";
import statisticsService from "../services/statistics.js";

export default {
  name: "dash-board",

  setup() {
    document.body.style.display = "block";
    const loadingEl = document.getElementById("loading");
    const body = document.getElementById("body");
    const products = ref([]);
    const customers = ref([]);
    const myRef = ref(null);
    const dateValue = ref();
    const formatter = ref({
      date: "MMM DD, YYYY",
      month: "MMM",
    });
    const loadingCus = ref(true);
    const loadingPro = ref(true);
    const statisticsSer = new statisticsService();
    const statistics = ref({
      Wish_list_all_products_id_count: 0,
      customers_added_products_count: 0,
      total_actions_in_wish_lists: 0,
      wish_lists_products_count: 0,
    });
    const statusTopProducts = ref(true),
      statusTopCustomers = ref(true),
      emptyProdutsMes = ref(""),
      emptyCustomersMes = ref("");

    // start watch date
    watch(dateValue, (value) => {
      let newDate = {};
      newDate["from_date"] =
        new Date(Date.parse([...value][0])).getTime() / 1000;
      newDate["to_date"] = new Date(Date.parse([...value][1])).getTime() / 1000;

      statisticsSer.getStatistics(newDate).then((dataReq) => {
        console.log(dataReq);
        if (dataReq.data.message === "there is no products in this date") {
          statistics.value = {
            Wish_list_all_products_id_count: 0,
            customers_added_products_count: 0,
            total_actions_in_wish_lists: 0,
            wish_lists_products_count: 0,
          };
        } else {
          statistics.value = dataReq.data.payload;
        }
      });

      // start get products
      statisticsSer
        .getTopProducts(newDate)
        .then((data) => {
          document.body.style.display = "block";
          loadingPro.value = false;
          if (data.data.success) {
            products.value = data.data.payload.data;
            statusTopProducts.value = data.data.success;
          } else {
            products.value = [];
            emptyProdutsMes.value = data.data.message;
            statusTopProducts.value = data.data.success;
          }
        })
        .catch((mes) => {
          emptyProdutsMes.value = mes.data.message;
          statusTopProducts.value = mes.data.success;
        });

      statisticsSer
        .getTopCustomer(newDate)
        .then((data) => {
          // console.log(data.data.payload);
          loadingCus.value = false;
          setTimeout(() => {
            body.classList.remove("d-none");
            loadingEl.style.display = "none";
          }, 100);
          if (data.data.success) {
            customers.value = data.data.payload.data;
            statusTopCustomers.value = data.data.success;
          } else {
            emptyCustomersMes.value = data.data.message;
            statusTopCustomers.value = data.data.success;
          }
        })
        .catch((mes) => {
          emptyCustomersMes.value = mes.data.message;
        });
    });
    return {
      products,
      formatter,
      dateValue,
      myRef,
      statisticsSer,
      statistics,
      statusTopProducts,
      emptyProdutsMes,
      emptyCustomersMes,
      statusTopCustomers,
      customers,
      loadingPro,
      loadingCus,
      loadingEl,
      body,
    };
  },
  components: {
    DataTable,
    Column,
    LitepieDatepicker,
  },
  mounted() {
    this.$emit("name", "Dashboard");
    this.getFirstLastDay();
    this.updateDueDate();
  },
  methods: {
    // get data for this mouth
    getFirstLastDay() {
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      let firstDayFormatted = firstDay.toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      });
      let lastDayFormatted = lastDay.toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      });
      this.dateValue = [firstDayFormatted, lastDayFormatted];
    },
    updateDueDate() {},
    someEvent() {
      console.log("change");
    },
    onRowClick(value) {
      console.log(value);
    },
  },
};
</script>

<style lang="scss">
.empty p {
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

#litepie {
  width: 40%;
}

.dashboard-wishlist {
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 12px;
  box-shadow: 0px 10px 13px -2px rgba(106, 115, 121, 0.05);
  padding-bottom: 50px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;

  .title-main {
    display: flex;
    padding: 0;
    margin-bottom: 20px;
    padding: 0;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;

    h3 {
      font-size: 14px;
      font-weight: 400;
      color: #202223;
    }
  }
}

@media (max-width: 992px) {
  .dashboard-wishlist .row-boxs {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 640px) {
  .dashboard-wishlist .row-boxs {
    grid-template-columns: 1fr;
    gap: 35px;
  }
}

.table-container-statistics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 35px;
}

.table-container-statistics h4 {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 12px;
  color: #202223;
}

.table-container-statistics .p-datatable .p-datatable-thead > tr > th {
  color: #202223;
  font-size: 14px;
  background: #fafbfb;
  font-weight: 600;
}

.table-container-statistics .p-datatable table {
  border: 1px solid #e7e7e780;
}

.title {
  display: flex;
  justify-content: space-between;
}

.dashboard-wishlist .row-boxs .box .main-box {
  padding: 22px 21px;
}

.dashboard-wishlist .row-boxs .box .main-box p {
  font-size: 14px;
}

.allProducts {
  font-size: 23px;
}

@media (max-width: 1400px) {
  .p-datatable .p-datatable-tbody > tr > td {
    font-size: 12px;
  }
}

@media (max-width: 700px) {
  .table-container-statistics {
    grid-template-columns: 1fr;
  }
}

.litepie-datepicker-overlay.open::before {
  z-index: 10;
}
</style>
