<template>
  <nav>
    <ul ref="ul">
      <li>
        <router-link to="/">
          <span
            ><svg
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="#6D6D6D"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 6.2614V16.5C16 17.341 15.3284 18 14.5 18H12.5C11.6716 18 11 17.341 11 16.5V12H5V16.4773C5 17.3182 4.32843 18 3.5 18H1.5C0.671573 18 0 17.3182 0 16.4773V6.2614C0 5.78286 0.228327 5.33304 0.614609 5.05058L7.205 0.23146C7.69141 -0.0771531 8.30859 -0.0771535 8.795 0.23146L15.3854 5.05058C15.7717 5.33304 16 5.78286 16 6.2614Z"
              /></svg></span
          ><span>Dashboard</span>
        </router-link>
      </li>
      <li>
        <router-link to="/wishlists">
          <span>
            <img
              src="@/assets/images/icons/Activity.svg"
              alt=""
              srcset=""
            /> </span
          ><span>Lists</span>
        </router-link>
      </li>
      <li>
        <router-link to="/products">
          <span>
            <img
              src="@/assets/images/icons/products.svg"
              alt=""
              srcset=""
            /> </span
          ><span>Products</span>
        </router-link>
      </li>
      <li>
        <router-link to="/customers">
          <span>
            <img
              src="@/assets/images/icons/cusomers.svg"
              alt=""
              srcset=""
            /> </span
          ><span>Customers</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
/* eslint-disable */
export default {
  name: "siteBar",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
nav {
  padding: 0 30px;
  width: 100%;
  background-color: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 10px 13px -2px #6a73790d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #6d6d6d;
}

ul {
  list-style: none;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 53px;
  border-bottom: 1px solid #cecece;
}

// ul li:last-of-type {
//   margin-left: auto;
// }

ul a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 15px 0;
}
ul li a {
  display: flex;
  align-items: center;
  transition: 0.2s;
  border-bottom: 3px solid #09a59b00;
}
ul a {
  transition: 0.2s;
  font-weight: 400;
}
ul a:hover {
  border-bottom: 3px solid #09a59a;
  color: #09a59a;
  svg {
    fill: #09a59a;
  }
}
ul a:hover a {
  color: #09a59a;
}

.active-tab {
  border-bottom: 3px solid #ea7e00;
  color: #ea7e00;
}
.active-tab svg {
  fill: #ea7e00;
}
ul li span {
  margin-right: 10px;
}

li a {
  text-decoration: none;
  color: #6d6d6d;
}
.active {
  text-decoration: none;
  color: #09a59a;
  border-bottom: 3px solid #09a59a;
  svg {
    fill: #09a59a;
  }
}
</style>
