import { createRouter, createWebHistory } from "vue-router";
import homeComponent from '../views/home.vue';
import editWishlist from '../views/editWishlist.vue';
import dashBoard from '../views/dashBoard.vue';
import productsComponent from '../views/productsComponent.vue';
import customersComponent from '../views/customersComponent.vue';
import wdgetsComponent from '../views/wdgetsComponent';


const routes = [
    {
        path: "/wishlists",
        component: homeComponent,
        name: "home"
    },
    {
        path: "/edit-wishlist/:id/:customer_id",
        component: editWishlist,
        name: "edit-wishlist"
    },
    {
        path: "/",
        component: dashBoard,
        name: "dash-board"
       
    },
    {
        path: "/products",
        component: productsComponent,
        name: "products"
    },
    {
        path: "/customers",
        component: customersComponent,
        name: "customers"
    },
    {
        path: "/widgets",
        component: wdgetsComponent,
        name: "wdgetsComponent"
    },

]


const router = createRouter({
    routes,
    history: createWebHistory(),
    linkActiveClass: "active"
})


let headersV = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${sessionStorage.getItem("auth_wishlist")}`
}

let urlParams = new URLSearchParams(window.location.search),
    session_token = urlParams.get('session_token'),
    object = {
        session_token: session_token
    };
document.body.style.display = "none";





// Start  Login widgets 
let loginWidgets = () => {
    fetch("https://wishlist-api.numinix.com/api/auth/login", {
            method: "post",
            headers: headersV,
            body: JSON.stringify(object),
        }).then(data => {
            return data.json()
        }).then(data => {
            sessionStorage.setItem("auth_wishlist", data.meta.token);
            if (!data.meta.redirect) {
                let product_widget = {
                    product_id: urlParams.get('pid'),
                    variant_id: urlParams.get('vid'),
                    product_widget: new URLSearchParams(window.location.search).has("widgets")
                }
                
                window.location.replace(`/widgets?product_widget=${encodeURIComponent(JSON.stringify(product_widget))}`);
                
            }else{
                let product_widget = {
                    product_widget: false
                }
                window.location.replace(`/widgets?product_widget=${encodeURIComponent(JSON.stringify(product_widget))}`);
            }

        }).catch(data => {
            console.log(data)
        })
}

let login = () => {
    // post session token
    if (urlParams.has('session_token'))
        fetch("https://wishlist-api.numinix.com/api/auth/login", {
            method: "post",
            headers: headersV,
            body: JSON.stringify(object),
        }).then(data => {
            return data.json()
        }).then(data => {
            sessionStorage.setItem("auth_wishlist", data.meta.token);
            if (data.meta.redirect) {
                window.parent.location.href = data.meta.authUrl;
            } else {
                window.location.replace("/");
            }
        }).catch(data => {
            console.log(data)
        })
    else if (urlParams.has('deploy.php'))
        console.log("fd")
    else {
        if (sessionStorage.getItem("auth_wishlist") === null) {
            window.parent.location.href = "https://admin.lexmodo.com/plugins/wishlist";
        }
    }

}


function fetchToCode() {
    // eslint-disable-next-line no-unused-vars
    let objectCode = {
        code: urlParams.get('code'),
        scope: urlParams.get('scope'),
        state: urlParams.get('state')
    }
    fetch("https://wishlist-api.numinix.com/api/auth/code", {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("auth_wishlist")}`
        },
        body: JSON.stringify(objectCode),
    }).then(data => { 
        return data.json()
    }).then(data => {
        if (data.acknowledge){
            window.location.href = "https://admin.lexmodo.com/plugins/wishlist"
        }
    }).catch(data => {
        console.log(data)
    })
}

if (urlParams.has('code'))
{
    console.log('code')
    fetchToCode();
}

// start working login auth 
if(urlParams.has('widgets')) {
    // if login widgets in main app this is way
    loginWidgets()
}else{
    // if login in main app this is way 
    login()
}

export default router

