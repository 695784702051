<template>
  <div class="row-boxs row-boxs-in">
    <div class="box">
      <div class="main-box">
        <img :src="imgSrc" alt="" />
        <div class="details">
          <h2 class="number">
            {{ totalActions }}
          </h2>
          <p>{{ pragraph }}</p>
        </div>
      </div>
      <div class="bottom-title">{{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalActions: {
      type: Number,
      required: true,
    },
    title: {
      required: false,
    },
    pragraph: {
      required: false,
    },
  },
  data() {
    return {
      imgSrc: "../assets/images/apps/actions.svg",
    };
  },
};
</script>
