/* eslint-disable */
<template>
  <section class="title-plugin">
    <div class=""></div>
  </section>

  <!---data.length --->
  <section class="table" v-if="errorLists === false">
    <!--start table -->
    <DataTable
      :value="data"
      :paginator="true"
      :rowsPerPageOptions="[10, 20, 30, 40]"
      :rows="10"
      ref="dt"
      :lazy="true"
      :loading="loading"
      :totalRecords="totalRecords"
      @page="onPage($event)"
      @sort="onPage($event)"
      :paginatorTemplate="{
        '640px': 'PrevPageLink CurrentPageReport NextPageLink',
        '960px':
          'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink',
        '1300px':
          'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        default:
          'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
      }"
      responsiveLayout="scroll"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    >
      <!-- content -->
      <Column field="customer_email" header="Customer Email" :sortable="true">
        <template #body="slotProps">
          <h4>
            {{
              slotProps.data.customer_email
                ? slotProps.data.customer_email
                : "anonymous"
            }}
          </h4>
        </template>
      </Column>
      <Column field="customer_name" header="Customer Name" :sortable="true">
        <template>
          <h4>
            {{
              slotProps.data.customer_name
                ? slotProps.data.customer_name
                : "anonymous"
            }}
          </h4>
        </template>
      </Column>
      <Column field="name" header="List Name" :sortable="true"></Column>
      <Column field="updated_at" header="Created At" :sortable="true"></Column>

      <Column
        field="description"
        header="Description"
        :sortable="true"
      ></Column>

      <Column bodyStyle="text-align: center">
        <template #body="slotProps">
          <router-link
            v-if="typeof slotProps.data.id !== 'undefined'"
            v-bind:to="{
              name: 'edit-wishlist',
              params: {
                id: slotProps.data.id,
                customer_id: slotProps.data.customer_id,
              },
            }"
          >
            <Button type="button" style="margin-right: 0.5em" class="defualt">
              <img src="../assets/images/icons/edit.png" alt="" srcset="" />
            </Button>
          </router-link>

          <Button
            @click="deleteWishlit(slotProps.data.id)"
            type="button"
            class="defualt"
          >
            <img src="../assets/images/icons/delete.png" alt="" srcset="" />
          </Button>
        </template>
      </Column>
      <!-- paginat -->
      <template #paginatorstart>
        <Button type="button" icon="pi pi-refresh" class="p-button-text" />
        <div>{{ totalRecords }} items</div>
      </template>
    </DataTable>
  </section>
  <section class="table empty" v-else>
    <img src="../assets/images/icons/empty.svg" alt="" srcset="" />
    <h4>Empty wishlist</h4>
    <p>You’ve not added any products on your wishlist</p>
  </section>

  <!--start delete wishlist-->
  <Dialog v-model:visible="deleteWishlist">
    <template #header>
      <h3>Delete confirmation</h3>
    </template>
    <p>Are You Sure You Want To Delete This List ?</p>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="removePopup()"
      />
      <Button label="Delete" @click="deleteNow()" autofocus />
    </template>
  </Dialog>

  <!---start delete wishlist -->
  <Dialog v-model:visible="addWishlist" class="addWishlist">
    <template #header>
      <h3>Add to wishlist</h3>
    </template>
    <form action="">
      <div class="body-popup">
        <div class="input customer_email">
          <label for="">Customer Email</label>
          <InputText
            type="text"
            name="email"
            @input="searchCustomers($event)"
            v-model="emailChoose"
          />
          <div class="search" :hidden="statusEmailSearch">
            <ul>
              <li
                v-for="item in dataCustomer"
                v-bind:key="item"
                @click="thismail(item)"
              >
                <span
                  >{{ item?.customer_first_name?.split("")[0]
                  }}{{ item?.customer_last_name?.split("")[0] }}</span
                >
                <div class="mail">
                  <h6>
                    {{ item?.customer_first_name }}
                    {{ item?.customer_last_name }}
                  </h6>
                  <p>{{ item?.customer_email }}</p>
                  <p>+1 07109626</p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="input">
          <label for="">List Name</label>
          <InputText type="text" name="email" v-model="createObj.name" />
        </div>
        <div class="input">
          <label for="">List Description</label>
          <InputText type="text" name="email" v-model="createObj.description" />
        </div>
        <div class="input">
          <label for="" class="privacy">Privacy Settings</label>
          <div class="chose-container">
            <div class="chose">
              <RadioButton
                name="city"
                value="Public"
                v-model="createObj.privacy_setting"
                for="Public"
              />
              <span id="Public">Public</span>
            </div>
            <div class="chose">
              <RadioButton
                name="city"
                value="Private"
                v-model="createObj.privacy_setting"
                for="Private"
              />
              <span id="Private">Private</span>
            </div>
          </div>
        </div>
      </div>
    </form>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="removePopup()"
      />
      <Button label="Add" @click="createNewWish()" autofocus />
    </template>
  </Dialog>

  <div></div>

  <div class="alert sucsess" v-if="sucsessSection">
    <div class="img">
      <img src="../assets/images/icons/true.svg" alt="" />
    </div>
    <div class="message">
      <h2>Added to wishlist</h2>
      <h4>View {{ emailCreatedWishlist }} wishlist here.</h4>
    </div>
    <button class="exit-alert" @click="sucsessSection = false">
      <img src="../assets/images/icons/exit.svg" alt="" />
    </button>
  </div>
</template>
<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import RadioButton from "primevue/radiobutton";

// start services
import wishListsService from "../services/wishlists.js";
import { ref } from "vue";

export default {
  name: "homeComponent",
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    InputText,
    RadioButton,
  },

  setup() {
    document.body.style.display = "block";
    setTimeout(() => {
      document.getElementById("body").classList.remove("d-none");
      document.getElementById("loading").style.display = "none";
    }, 100);
    const data = ref([]);
    const wishlistService = new wishListsService();
    const loading = true;
    const lazyParams = ref({});
    const totalRecords = ref(0);
    const selectedCustomers = ref(null);
    const selectAll = ref(false);
    const addWishlist = ref(false);
    const deleteWishlist = ref(false);
    const Public = ref(null);
    const Private = ref(null);
    const statusEmailSearch = ref(true);
    const thisItemDeleteNow = ref("");
    const dataCustomer = ref([{}]);
    const emailChoose = ref("");
    const createObj = ref({
      privacy_setting: null,
      description: "",
      name: "",
    });
    const errorLists = ref(false);

    const sucsessSection = ref(false);
    const emailCreatedWishlist = ref(null);
    return {
      data,
      wishlistService,
      loading,
      lazyParams,
      totalRecords,
      selectedCustomers,
      selectAll,
      addWishlist,
      deleteWishlist,
      Public,
      Private,
      statusEmailSearch,
      thisItemDeleteNow,
      dataCustomer,
      emailChoose,
      createObj,
      sucsessSection,
      emailCreatedWishlist,
      errorLists,
    };
  },

  mounted() {
    this.$emit("name", "Activity");
    this.loading = ref(true);
    this.lazyParams = {
      first: 0,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
      rows: 10,
    };

    this.loadLazyData();
  },
  methods: {
    loadLazyData() {
      this.loading = true;
      this.wishlistService.getWishlists(this.lazyParams).then((data) => {
        this.loading = false;
        if (data.success) {
          this.data = data.payload.data;
          this.totalRecords = data.payload.total;
          this.errorLists = false;
        } else {
          this.errorLists = true;
        }
      });
    },
    onPage(event) {
      console.log(event.rows);
      this.lazyParams = {
        ...this.lazyParams,
        rows: event.rows,
        sortOrder: event.sortable,
        sortField: event.sortField,
        page: event.page,
      };
      this.loadLazyData();
    },
    addWish() {
      this.addWishlist = true;
    },
    deleteWishlit(id) {
      this.thisItemDeleteNow = id;
      this.deleteWishlist = true;
    },
    deleteNow() {
      this.deleteWishlist = false;
      this.wishlistService.deleteList(this.thisItemDeleteNow).then((data) => {
        if (data.data.status === "Success") this.loadLazyData();
      });
    },
    searchCustomers(event) {
      if (event.target.value.length > 1)
        this.wishlistService
          .getCustomers(`${event.target.value}`)
          .then((data) => {
            this.dataCustomer = data.data.data;
            console.log(this.dataCustomer);
            this.statusEmailSearch = false;
          });
      else this.dataCustomer = [];
    },
    thismail(cus) {
      console.log(cus);
      this.emailChoose = cus.customer_email;
      this.customerIdChose = cus.customer_id;
      this.statusEmailSearch = true;
    },
    createNewWish() {
      let objectCreateNewWihlist = {
        ...this.createObj,
        customer_email: this.emailChoose,
        customer_id: this.customerIdChose,
      };
      this.addWishlist = false;
      this.wishlistService.createList(objectCreateNewWihlist).then((data) => {
        if (data.status) this.loadLazyData();
        this.sucsessSection = true;
        this.emailCreatedWishlist = data.data.data.customer_email;
        this.createObj = {
          privacy_setting: null,
          description: "",
          name: "",
        };
        this.emailChoose = "";
      });
    },
    removePopup() {
      this.deleteWishlist = false;
      this.addWishlist = false;
    },
  },
};
</script>

<style scoped>
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 140px 0;
}
.empty img {
  margin-bottom: 20px;
}
.empty h4 {
  font-size: 18px;
  font-weight: 400;
}
.empty p {
  font-size: 14px;
  font-weight: 400;
  color: #6d6d6d;
}
.table {
  width: 100%;
}
</style>
