import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import router from "./router/index";
import LitepieDatepicker from 'litepie-datepicker';
import "./assets/tailwimd.css";

const app = createApp(App);
app.use(PrimeVue);
app.use(router);
app.use(LitepieDatepicker);
app.mount('#app');



