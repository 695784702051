<template>
  <!--start products-->
  <section class="wdgets" v-if="wdgetsStatus">
    <statisticComponent
      :totalActions="counter"
      :title="'Wishlist count'"
      :pragraph="'Number of times this product has been added to wishlists'"
    ></statisticComponent>
    <div class="table-cusomers">
      <h3>Recent customers who have added the product to their wishlist</h3>

      <!-- start top cusomers -->
      <DataTable :value="data" :paginator="true" :rows="7" :lazy="true">
        <Column field="customer_email" header="Cusomer Email"></Column>
      </DataTable>
    </div>
  </section>
  <section class="install" v-else>
    <h3>please Install your wishlist app</h3>
    <a href="https://bigpawoliveoil.lexmodo.com/plugins/wishlist"
      >Install now</a
    >
  </section>
</template>

<script>
import { ref } from "vue";
import statisticComponent from "../components/statisticComponent.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

import axios from "axios";

export default {
  name: "wdgetsComponent",

  setup() {
    const data = ref([{}]),
      counter = ref(0),
      objectProductWidget = ref({}),
      wdgetsStatus = ref(true);

    return { data, counter, objectProductWidget, wdgetsStatus };
  },
  mounted() {
    this.objectProductWidget = JSON.parse(
      new URLSearchParams(window.location.search).get("product_widget")
    );
    this.wdgetsStatus = this.objectProductWidget.product_widget;
    document.body.style.display = "inline";
    document.getElementById("body")?.classList.remove("d-none");
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("auth_wishlist")}`,
    };

    axios
      .post(
        "https://wishlist-api.numinix.com/api/Widgets",
        this.objectProductWidget,
        { headers: headers }
      )
      .then((res) => {
        document.getElementById("loading").style.display = "none";
        console.log(res.data.success);
        if (res.data.success) {
          this.counter = res.data.payload.count_of_products_added_to_lists;
          if (res.data.payload.LatestCustomers.length > 0) {
            this.data = res.data.payload.LatestCustomers;
          } else {
            this.data = [{ customer_email: "No Customers added this product" }];
          }
        } else {
          console.log([{ customer_email: "No Customers added this product" }]);
          this.data = [{ customer_email: "No Customers added this product" }];
        }
      });
  },

  components: {
    statisticComponent,
    DataTable,
    Column,
  },
};
</script>

<style lang="scss">
.main-box {
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 26px 32px;
  background: #e3fff8;
  border-top-right-radius: 9px;
  min-height: 140px;
  border-top-left-radius: 9px;
  justify-content: center;
  text-align: center;
}
.row-boxs-in {
  grid-template-columns: 1fr !important;
}
.row-boxs .box {
  width: 100%;
}
.wdgets {
  h1 {
    text-align: center;
    margin-bottom: 25px;
    font-size: 21px;
  }
}

body {
  background-color: #ffffff !important;
}
#app {
  width: 100%;
}
.pearnt-loading {
  display: none;
}
.table-cusomers {
  width: 100%;
  h3 {
    font-size: 18px;
    color: black;
    margin-bottom: 10px;
  }
}
.install {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    text-align: center;
    font-size: 21px;
    text-transform: capitalize;
  }
  a {
    background-color: #025ca1;
    color: #fff;
    text-transform: capitalize;
    font-size: 16px;
    border-radius: 5px;
    padding: 9px 15px;
    margin-top: 21px;
  }
}
</style>
