<template>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
    rel="stylesheet"
  />
  <div class="container d-none" id="body">
    <upperBar></upperBar>
    <!--start title-->
    <div class="title flex justify-content-between">
      <h3>{{ componentName }}</h3>
      <div class="actions" v-if="statusProductWidget !== '/widgets'">
        <!-- <searchInput></searchInput> -->
        <button @click="addWish()">
          <img src="@/assets/images/icons/add.svg" alt="" srcset="" /> Add new
          wishlist
        </button>
      </div>
    </div>
    <siteBar v-if="statusProductWidget !== '/widgets'"></siteBar>
    <router-view @name="getName" :function="getWishlists"></router-view>
  </div>

  <div id="loading" class="pearnt-loading">
    <div class="loading"></div>
  </div>

  <!---start delete wishlist -->
  <Dialog v-model:visible="addWishlist" class="addWishlist">
    <template #header>
      <h3>Add to wishlist</h3>
    </template>
    <form action="">
      <div class="body-popup">
        <div class="input customer_email">
          <label for="">Customer Email</label>
          <InputText
            type="text"
            name="email"
            @input="searchCustomers($event)"
            v-model="emailChoose"
          />
          <div class="search" :hidden="statusEmailSearch">
            <ul>
              <li
                v-for="item in dataCustomer"
                v-bind:key="item"
                @click="thismail(item)"
              >
                <span
                  >{{ item?.customer_first_name?.split("")[0]
                  }}{{ item?.customer_last_name?.split("")[0] }}</span
                >
                <div class="mail">
                  <h6>
                    {{ item?.customer_first_name }}
                    {{ item?.customer_last_name }}
                  </h6>
                  <p>{{ item?.customer_email }}</p>
                  <p>+1 07109626</p>
                </div>
              </li>
              <li>{{ ErrorMes }}</li>
            </ul>
          </div>
        </div>

        <div class="input">
          <label for="">List Name</label>
          <InputText type="text" name="email" v-model="createObj.name" />
        </div>
        <div class="input">
          <label for="">List Description</label>
          <InputText type="text" name="email" v-model="createObj.description" />
        </div>
        <div class="input">
          <label for="" class="privacy">Privacy Settings</label>
          <div class="chose-container">
            <div class="chose">
              <RadioButton
                name="city"
                value="Public"
                v-model="createObj.privacy_setting"
                for="Public"
              />
              <span id="Public">Public</span>
            </div>
            <div class="chose">
              <RadioButton
                name="city"
                value="Private"
                v-model="createObj.privacy_setting"
                for="Private"
              />
              <span id="Private">Private</span>
            </div>
          </div>
        </div>
      </div>
    </form>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="removePopup()"
      />
      <Button
        label="Add"
        @click="createNewWish()"
        autofocus
        :disabled="statusDisabledCreateWishlist"
      />
    </template>
  </Dialog>
  <div class="alert sucsess" v-if="sucsessSection">
    <div class="img">
      <img src="../src/assets/images/icons/true.svg" alt="" />
    </div>
    <div class="message">
      <h2>Added to wishlist</h2>
      <h4>
        View {{ emailCreatedWishlist }} wishlist
        <a href="/wishlists">here</a>

        .
      </h4>
    </div>
    <button class="exit-alert" @click="deleteSuc()">
      <img src="../src/assets/images/icons/exit.svg" alt="" />
    </button>
  </div>
  <confirm-message v-if="errorsStatus" @confirm-message="hideAlert">
    <template #title>Worng</template>
    <template #body>
      <ul>
        <li v-for="(value, index) in errorsCreate" v-bind:key="index">
          {{ value[0] }}
        </li>
      </ul>
    </template>
  </confirm-message>
</template>

<script>
/* eslint-disable */
import { ref } from "vue";
import upperBar from "@/components/sections/upperBar.vue";
import siteBar from "@/components/sections/sitebar.vue";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import RadioButton from "primevue/radiobutton";
import wishListsService from "../src/services/wishlists";
import Button from "primevue/button";
import router from "@/router";
import searchInput from "@/components/sections/searchBarComponent.vue";
import confirmMessage from "@/components/sections/confirmMessage.vue";

export default {
  name: "App",
  setup() {
    const componentName = ref("WishList");
    const addWishlist = ref(false);
    const statusEmailSearch = ref(false);
    const statusDisabledCreateWishlist = ref(false);
    const emailCreatedWishlist = ref("");
    const sucsessSection = ref(false);
    const createObj = ref({
      privacy_setting: null,
      description: "",
      name: "",
    });
    const dataCustomerCreated = ref({
      id: "",
      customer_id: "",
    });
    const emailChoose = ref("");
    const ErrorMes = ref("");
    const wishlistService = new wishListsService();
    const errorsCreate = ref({});
    const errorsStatus = ref(false);
    const statusProductWidget = ref("");

    // expose to template and other options API hooks
    return {
      componentName,
      addWishlist,
      createObj,
      emailChoose,
      wishlistService,
      statusEmailSearch,
      statusDisabledCreateWishlist,
      emailCreatedWishlist,
      dataCustomerCreated,
      sucsessSection,
      errorsCreate,
      errorsStatus,
      statusProductWidget,
    };
  },
  components: {
    upperBar,
    siteBar,
    Dialog,
    RadioButton,
    Button,
    InputText,
    searchInput,
    confirmMessage,
  },
  mounted() {
    this.statusProductWidget = window.location.pathname;


  },
  methods: {
    hideAlert(value) {
      this.errorsStatus = false;
    },
    getName(value) {
      this.componentName = value;
      console.log(value);
    },

    searchCustomers(event) {
      // if (event.target.value.length > 1)
      //   this.wishlistService
      //     .getCustomers(`${event.target.value}`)
      //     .then((data) => {
      //       console.log(data)
      //       this.dataCustomer = data.data.data;
      //       this.statusEmailSearch = false;
      //     }).catch(data => {
      //       this.ErrorMes = data.response.data.message;
      //     })
      // else this.dataCustomer = [];
    },
    thismail(cus) {
      this.emailChoose = cus.customer_email;
      this.customerIdChose = cus.customer_id;
      this.statusEmailSearch = true;
      this.statusDisabledCreateWishlist = false;
      console.log(this.statusDisabledCreateWishlist);
    },
    createNewWish() {
      let objectCreateNewWihlist = {
        ...this.createObj,
        customer_email: this.emailChoose,
        customer_id: this.customerIdChose,
      };
      this.addWishlist = false;
      this.wishlistService
        .createList(objectCreateNewWihlist)
        .then((data) => {
          if (data.data.status === "Success")
            this.dataCustomerCreated = data.data.data;
          console.log(this.dataCustomerCreated);
          this.sucsessSection = true;
          this.emailCreatedWishlist = data.data.data.customer_email;
          router.replace({ path: "/" });

          this.createObj = {
            privacy_setting: null,
            description: "",
            name: "",
          };
          this.emailChoose = "";
        })
        .catch((data) => {
          if (data.response.status === 422) {
            this.errorsStatus = true;
            this.errorsCreate = data.response.data.errors;
          }
        });
    },
    removePopup() {
      this.addWishlist = false;
    },
    addWish() {
      this.addWishlist = true;
    },

    deleteSuc() {
      this.sucsessSection = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/global.scss";

button {
  align-items: center;
}
.search {
  display: none;
}
.alert {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  gap: 15px;
  width: 565px;
  padding: 16px 23px;
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  top: 25px;
  background-color: #e7fff9;
  padding: 15px 15px;
  h2 {
    font-size: 14px;
    font-weight: 600;
    color: #202223;
    margin-bottom: 5px;
    font-family: "Plus Jakarta Sans";
  }
  button {
    border: none;
    background-color: #fff0;
    cursor: pointer;
  }
  h4 {
    font-size: 14px;
    font-weight: 400;
    font-family: "Plus Jakarta Sans";
  }
  &.sucsess {
    box-shadow: 9px 6px 24px -2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    border: 1px solid #07d09e;
  }
}

.container {
  width: 100% !important;
  max-width: 100% !important;
}

// start loading
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.loading {
  width: 40px;
  height: 40px;
  margin: 110px auto 0;
  border: solid 5px #144a9b;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.pearnt-loading {
  display: flex;
  width: 100%;
  height: 75%;
  position: absolute;
  top: 0;
  align-items: center;
  justify-content: center;
}
.row-boxs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  padding-bottom: 30px;
  border-bottom: 1px solid black;
  margin-bottom: 30px;
  /* border: 1px solid #E7E7E7; */
  border-color: #e7e7e7;

  .box {
    border-bottom: 3px solid #09a59a;
    box-shadow: 0px 5px 19px #e3fff8;
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;

    .main-box {
      display: flex;
      align-items: center;
      gap: 25px;
      padding: 26px 32px;
      background: #e3fff8;
      border-top-right-radius: 9px;
      min-height: 140px;
      border-top-left-radius: 9px;

      p {
        margin: 0;
        color: #6d6d6d;
      }

      h2 {
        font-size: 36px;
        color: #202223;
        font-weight: 700;
      }
    }

    .bottom-title {
      text-align: center;
      padding: 12px;
      background: #c8f7eb;
      border-radius: 8px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &.customers {
      border-bottom: 3px solid #f8d847;

      .main-box {
        background: #fff8d6;
      }

      .bottom-title {
        background: #f8edbb;
      }
    }

    &.products {
      border-bottom: 3px solid #60a3d8;

      .main-box {
        background: #d4ecff;
      }

      .bottom-title {
        text-align: center;
        padding: 12px;
        background: #b2dbfc;
        border-radius: 8px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}
</style>
