import axios from 'axios'
export default class wishlistService {
    headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("auth_wishlist")}`
    }
    getWishlists(params) {
        return axios.get(`https://wishlist-api.numinix.com/api/lists`, {
        params: {
            dt_params: JSON.stringify({
                ...params
            })
        },
        headers: {
            ...this.headers
        },
        }).then(res => {
            return res.data
        })
    }
    deleteList(wish_list_id) {
        return axios.post('https://wishlist-api.numinix.com/api/lists/delete', {
            wish_list_id
        },
        {headers: {
            ...this.headers
        }})
        .then(function (response) {
            return response
        })
    }

    // start get customers 
    getCustomers(search) {
        return axios.get(`https://wishlist-api.numinix.com/api/customer/?search=${search}`, 
        {headers: {
            ...this.headers
        }})
        .then(function (response) {
            return response
        })
    }

    // start create list
    createList(form) {
        return axios.post('https://wishlist-api.numinix.com/api/lists', form , 
        {headers: {
            ...this.headers
        }})
        .then(function (response) {
            return response
        })
    }


    // start get list 
    getList(list_id, cusId) {
        return axios.get(`https://wishlist-api.numinix.com/api/lists/${list_id}?customer_id=${cusId}`
        ,{headers: {
            ...this.headers
        }})
        .then(function (response) {
            return response
        })
    }

    // start updateLists
    updateItem(form){
        return axios.put(`https://wishlist-api.numinix.com/api/lists/${form.id}`, form
        ,
        {headers: {
            ...this.headers
        }})
        .then(function (response) {
            return response
        })
    }


    // start get Total customers
    getTotalCustomers(params) {
        return axios.get(`https://wishlist-api.numinix.com/api/TotalCustomers`, {
            params: {
                dt_params: JSON.stringify({
                    ...params
                })
            },
            headers: {
                ...this.headers
            },
            }).then(res => {
                return res.data
        })
    }


    // get all products in all lists
    getAllProducts(params) {
        return axios.get(`https://wishlist-api.numinix.com/api/TotalProducts`, {
        params: {
            dt_params: JSON.stringify({
                ...params
            })
        },
        headers: {
            ...this.headers
        },
        }).then(res => {
            return res.data
        })
    }



}
