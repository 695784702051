import axios from "axios";
export default class statisticsService {
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("auth_wishlist")}`,
  };

  constructor() {}

  getStatistics(date) {
    return axios
      .get("https://wishlist-api.numinix.com/api/Statistics", {
        headers: {
          ...this.headers,
        },
        params: date,
      })
      .then((data) => data);
  }

  getTopCustomer(date) {
    return axios
      .get("https://wishlist-api.numinix.com/api/TopCustomers", {
        headers: {
          ...this.headers,
        },
        params: date,
      })
      .then((data) => data);
  }

  getTopProducts(date) {
    return axios
      .get("https://wishlist-api.numinix.com/api/TopProducts", {
        headers: {
          ...this.headers,
        },
        params: date,
      })
      .then((data) => data);
  }
}
