<template>
  <!--start products-->
  <section class="products">
    <DataTable
      :value="products"
      v-if="error == false"
      :paginator="true"
      :loading="loading"
      :rowsPerPageOptions="[10, 20, 30, 40]"
      :rows="10"
      ref="dt"
      :lazy="true"
      :totalRecords="total"
      @page="onPage($event)"
      @sort="onPage($event)"
    >
      <Column field="product_name" header="Product Name">
        <template #body="slotProps">
          <a href="#" class="view_product">
            <img :src="slotProps.data.product_image" alt="" srcset="" />
            <h4>{{ slotProps.data.product_name }}</h4>
          </a>
        </template>
      </Column>

      <Column field="counter" header="No. of items in list"></Column>
      <Column field="updated_at" header="Last Wishlist Addition On"></Column>
      <Column field="" header="View product">
        <template #body="slotProps">
          <button
            :href="slotProps.data.product_id"
            class="view-item"
            @click="redirect(slotProps.data.product_id)"
          >
            View Product
            <img src="../assets/images/icons/view.svg" alt="" />
          </button>
        </template>
      </Column>
    </DataTable>

    <!-- start empty products -->
    <section class="empty" v-if="error">
      <img src="../assets/images/icons/empty.svg" alt="" srcset="" />
      <h4>Empty Products</h4>
      <p>You’ve not added any products</p>
    </section>
  </section>
</template>

<script>
import { ref } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import wishListsService from "../services/wishlists.js";

export default {
  name: "productsComponent",

  setup() {
    const products = ref([]);
    const wishlistService = new wishListsService();
    const lazyParams = {
      first: 0,
      sortField: null,
      sortOrder: null,
    };
    const total = ref(0);
    const error = ref(false);
    const loading = ref(true);
    return {
      products,
      wishlistService,
      lazyParams,
      total,
      error,
      loading,
    };
  },
  components: {
    DataTable,
    Column,
  },
  mounted() {
    this.loadLazyData();
    this.$emit("name", "Products");
  },
  methods: {
    loadLazyData() {
      this.wishlistService.getAllProducts(this.lazyParams).then((data) => {
        this.loading = false;
        if (data.success) {
          console.log(data.payload.data);
          this.products = data.payload.data;
        } else {
          this.error = true;
        }
      });
    },
    onPage(data) {
      this.lazyParams = data;
      this.loadLazyData();
    },

    redirect(link) {
      window.parent.location.href = `https://admin.lexmodo.com/products/${link}`;
    },
  },
};
</script>

<style lang="scss">
.products {
  // start view_product
  .view_product {
    display: flex;
    align-items: center;
    font-weight: 15px;
    font-size: 14px;
    font-weight: 400;
    gap: 25px;
    img {
      width: 62px;
      height: 62px;
    }
  }
  // start view-item
  .view-item {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    gap: 10px;
    color: #1575bf;
    background-color: #fff;
    border: none;
  }
}
.p-paginator {
  display: flex;
  justify-content: flex-end;
}
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 140px 0;
}
.empty img {
  margin-bottom: 20px;
}
.empty h4 {
  font-size: 18px;
  font-weight: 400;
}
.empty p {
  font-size: 14px;
  font-weight: 400;
  color: #6d6d6d;
}
</style>
