<template>
    <div class="backdrop"></div>
    <div class="main">
      <h5>
        <slot name="title"></slot>
      </h5>
      <p>
        <slot name="body"></slot>
      </p>
      <div class="actions">
        <base-button class="flat" @click="$emit('confirmMessage', false)"
          >Cancel</base-button
        >
      
      </div>
    </div>
  </template>
  
  <script>
  export default {
    emits: ["confirmMessage"],
  };
  </script>
  
  <style scoped>
  .backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.171);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1100;
  }
  base-button{
    cursor: pointer;
  }
  .main {
    z-index: 1200;
    display: flex;
    flex-direction: column;
    padding-top: 27px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 0px 10px 13px -2px #6a73790d;
    width: 600px;
    height: 241px;
    border-radius: 8px;
  }
  .main h5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 23px;
  }
  
  p,
  h5 {
    margin-left: 33px;
  }
  
  .actions {
    padding: 17px 31px 25px 0;
    display: flex;
    gap: 12px;
    align-self: flex-end;
    margin-top: auto;
  }
  </style>