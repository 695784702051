<template>
    <div class="upperBar">
        <div class="row">
            <div class="title-upper">
                <img src="../../assets/images/icons/logo.svg" alt="" srcset="">
                <h4  >WishList</h4>
            </div>
            <div class="left-space">
                <button><img src="../../assets/images/icons/chose.svg" alt="" srcset=""></button>
            </div>
        </div>
    </div>
</template>


<script>
/* eslint-disable */ 
export default {
  name: 'upperBar',
    methods: {
       
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .upperBar{
       .row{
        display: grid;
        grid-template-columns: 1fr 1fr;
        border: 1px solid #CCCCCC;
        border-radius: 5px;
        padding: 10px 10px;
       }

       .title-upper{
        display: flex;
        align-items: center;
        h4{
            color: #202223;
            font-weight: 300;
            font-size: 16px;
        }
       }
       .left-space{
        display: flex;
        align-items: center;
        gap: 15px;
        justify-content: flex-end;
        button{
            background: rgba(255, 255, 255, 0);
            border: none;
            cursor: pointer;
        }
       }
    }
</style>
