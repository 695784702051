<template>
    <input :type="type || 'text'" :placeholder="placeholder" />
    <button>
        <img src="@/assets/images/icons/search.svg" alt="" srcset="">
  </button>
</template>
  
  <script>
  export default {
    name: "searchInput",
    props: ["type", "placeholder", "width"],
    
  };
  </script>
  
  <style scoped>
  input {
    height: 45px;
    padding-left: 18px;
    border-radius: 8px;
    color: #6d6d6d;
    border: #d8d8d8 1px solid;
  }
  
  input:focus {
    outline: none;
  }
  
  .search-input {
    width: 316px;
    height: 45px;
    border-radius: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }


  /* button style */
  button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  height: 45px;
  padding: 0 15px;
  background-color: #ffb054;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #202223;
  border: none;
}

button:hover,
button:active {
  background-color: #1166a8;
  color: #fff;
}

.small {
  height: 40px;
}

.flat {
  background-color: #fff;
  border: 1px solid #cecece;
}

.red-text {
  color: #c83e25;
}

.search-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 50px;
}


  </style>