<template>
  <div class="title flex justify-content-between">
    <router-link to="/" >
    <h3>
      <svg
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.00019 9.50019C4.80819 9.50019 4.61619 9.42669 4.46994 9.28044L0.719938 5.53044C0.426687 5.23719 0.426687 4.76319 0.719938 4.46994L4.46994 0.719938C4.76319 0.426687 5.23719 0.426687 5.53044 0.719938C5.82369 1.01319 5.82369 1.48719 5.53044 1.78044L2.31069 5.00019L5.53044 8.21994C5.82369 8.51319 5.82369 8.98719 5.53044 9.28044C5.38419 9.42669 5.19219 9.50019 5.00019 9.50019Z"
          fill="#6D6D6D" />
      </svg>
      Edit Wishlist
    </h3>
  </router-link>
  </div>
  <section class="edit-wishlsit">
    <div class="row">
      <div class="section form-edit">
        <form action="">
          <h5 class="	">Details</h5>
          <div class="row-inputs">
            <div class="input">
              <label for="">Name</label>
              <InputText
                type="text"
                name="Name"
                v-model="data.customer_email" />
            </div>
            <div class="input">
              <label for="">Customer Name</label>
              <InputText
                type="text"
                name="customer_name"
                v-model="data.customer_name" />
            </div>
            <div class="input">
              <label for="">Date created</label>
              <Calendar v-model="value" :showButtonBar="false" />
            </div>
            <div class="input">
              <label for="">List Name</label>
              <InputText type="text" name="name" v-model="data.name" />
            </div>
            <div class="input description">
              <label for="">List Description</label>
              <InputText
                type="text"
                name="description"
                v-model="data.description" />
            </div>
            <div class="input">
              <label for="" class="privacy">Privacy Settings</label>
              <div class="chose-container">
                <div class="chose">
                  <RadioButton
                    name="city"
                    value="public"
                    v-model="data.privacy_setting" />
                  <span id="rb1">Public</span>
                </div>
                <div class="chose">
                  <RadioButton
                    name="city"
                    value="private"
                    v-model="data.privacy_setting" />
                  <span id="rb2">Private</span>
                </div>
              </div>
            </div>
          </div>
          <!--start actions-->
          <div class="actions">
            <router-link to="/">
              <button class="button-cancel">Cancel</button>
            </router-link>
            <button class="button" @click="updateItem()" type="button">
              Update
            </button>
          </div>
        </form>
      </div>
      <div class="section items">
        <h5>Products</h5>
        <div class="table-products"  v-if="products">
          <div class="header">
            <div class="row-table">
              <h5>Product name</h5>
              <h5>Date added</h5>
            </div>
          </div>
          
          <div class="body"   v-for="item in data.products" :key="item?.product_name ">
            <div class="row-table product-main">
              <div class="product">
                <div class="img">
                  
                  <img v-bind:src="item.product_image" />
                </div>
                <div class="content">
                  <a href="">{{ item?.product_name }}</a>
                  <ul>
                    <li><span>Variant Id:</span> {{ item?.variant_id }}</li>
                  </ul>
                </div>
              </div>
              <div class="date">
                <h5>11/12/2022</h5>
              </div>
            </div>
          </div>
         
        </div>
        <div class="empty" v-if="products == false">
            <p style="font-size: 16px; text-transform: capitalize">no products for this list</p>
          </div>
      </div>
    </div>
  </section>
  <div></div>


<!-- sucsses Up date -->
  <div class="alert sucsess" v-if="sucsessSection" >
      <div class="img">
          <img src="../assets/images/icons/true.svg" alt="">
      </div>
      <div class="message">
          <h2>Updated Wishlist</h2>
          <h4> Return To All Lists . 
           
           
          <router-link to="/" class="router">click here </router-link>

          </h4>
      </div>
      <button class="exit-alert" @click="sucsessSection = false">
          <img src="../assets/images/icons/exit.svg" alt="">
      </button>
  </div>
</template>

<script>

import InputText from "primevue/inputtext";
import RadioButton from "primevue/radiobutton";
import Calendar from "primevue/calendar";
import { ref } from "vue";
// start services
import { useRoute } from "vue-router";
import CarService from "../services/wishlists.js";
export default {
  name: "editWishlist",

  setup() {
    const city = ref(null);
    const value = ref(null);
    const id = ref("");
    const wishlistService = new CarService();
    const data = ref({
      customer_email: "",
      customer_name: "",
      description: "",
      name: "",
      privacy_setting: "",
    });
    const products = ref(false);
    const sucsessSection = ref(false);

    return {
      city,
      value,
      id,
      wishlistService,
      data,
      products,
      sucsessSection
    };
  },

  mounted() {
    this.$emit('name', "Edit Wishlist");
    const route = useRoute();
    this.id = route.params.id;
    this.cusId = route.params.customer_id;
    this.wishlistService
      .getList(this.id, this.cusId)
      .then((data) => {
        this.data = data.data.data;
       
        if(data.data.data.products.length > 0){
          this.products = data.data.data.products;
          console.log(data.data.data.products)
        }
        else
          this.products = false
        this.value = new Date(this.data.created_at * 1000);
      })
      .catch((data) => {
        console.log(data.data.data);
      });
  },
  methods: {
    updateItem() {
      let dataPostUpdate = {
        ...this.data,
        created_at: Math.floor(new Date(this.value).getTime() / 1000),
      };
      this.wishlistService.updateItem(dataPostUpdate).then(data => {
        if(data?.data.status === "Success") {
          this.sucsessSection = true;
        }
      })
    },
  },
  components: {
    InputText,
    RadioButton,
    Calendar,
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/edit_wishlist.scss";

.router{
  text-decoration: revert;
  color: black;
}
</style>
